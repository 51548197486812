import React from "react";
import "./privacy.css";
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";
import { useTranslation } from "react-i18next";

function TermsOfServices() {
  const { t } = useTranslation();

  const currentYear = new Date().getFullYear();

  return (
    <>
      <Navbar />
      <div className="privacyPolicy container">
        <h3> {t("TermsOfServiceAgreement")}</h3>
        <ol>
          <li>
            <h6 className="mt-5">
              {t("Introduction and Acceptance of Terms")}
            </h6>
            <ul>
              <li>
                Title: These Terms of Service ("Terms") govern your access to
                and use of LawvocateAI's website and services ("Services")
              </li>

              <li>
                Acceptance: By clicking "I Agree" or similarly labeled button or
                checkbox next to this Agreement, you acknowledge that you have
                read, understood, and agree to be bound by these Terms. If you
                do not agree to all the terms, you may not use the Services
              </li>
            </ul>

            {/* <p>{t("Acceptance_of_Terms")}</p> */}
          </li>
          <li>
            <h6>{t("Clear Presentation and Affirmative Action")}</h6>
            {/* <p>{t("Privacy")}</p> */}
            <ul>
              <li>
                Notice: The Terms of Service, along with the Privacy Policy and
                the Disclaimer, will be presented conspicuously on the
                registration, login, or checkout page
              </li>

              <li>
                Uninterrupted Access: You will have the opportunity to review
                these Terms thoroughly via a scroll box or a separate pop-up
                window without leaving the signup interface.
              </li>
              <li>
                Record of Consent: LawvocateAI will maintain records of your
                acceptance, including timestamp and IP address logs, which
                confirm your explicit consent to these Terms
              </li>
            </ul>
          </li>
          <li>
            <h6>{("Privacy")}</h6>
            {/* <p>{t("Amendments")}</p> */}

            <ul>
              <li>
                Your use of the Services is subject to our Privacy Policy, which
                is available on the Website and incorporated by reference into
                this Agreement. The policy explains how we collect, use, and
                disclose information regarding your privacy. When you access or
                use the Services, you signify your agreement to the Privacy
                Policy and this Agreement.
              </li>
            </ul>
          </li>
          <li>
            <h6> {t("Data Collection and User Information")}</h6>
            {/* <p>{t("User_Conduct")}</p> */}

            <ul>
              <li>
                Data Collection and Use: We collect the following personal data:
                <ul>
                  <li>
                    Information you provide directly, such as your name, email
                    address, and contact information.
                  </li>
                  <li>
                    Information collected automatically includes your IP
                    address, device type, and browsing activity
                  </li>
                </ul>
              </li>
              <li>
                We use this data to:
                <ul>
                  <li>Provide and improve the Services.</li>
                  <li>Send you marketing communications (with your consent)</li>
                  <li>Comply with legal obligations.</li>
                </ul>
              </li>

              <li>
                The legal basis for processing your data is
                <ul>
                  <li>Contract: To provide the Services you requested</li>
                  <li>
                    Legitimate interest: To improve the Services and send
                    marketing communications (with your consent)
                  </li>
                  <li>
                    Consent: We will ask for your explicit consent for other
                    purposes
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <h6> {t("User Rights")}</h6>
            {/* <p>{t("Compliance_with_Laws")}</p>
            <p>{t("LegalComplianceAcknowledgement")}</p> */}

            <ul>
              <li>
                You have the following rights under the GDPR and CCPA
                <ul>
                  <li>
                    Right to access: You can request a copy of your personal
                    data.
                  </li>
                  <li>
                    Right to rectification: You can request to correct
                    inaccurate data
                  </li>
                  <li>Right to erasure: You can request to delete your data</li>
                  <li>
                    Right to restrict processing: You can restrict how we use
                    your data.
                  </li>
                  <li>
                    Right to data portability: You can request your data in a
                    transferable format
                  </li>
                  <li>
                    Right to object: You can object to processing your data for
                    marketing purposes
                  </li>
                </ul>
              </li>
              <li>
                To exercise these rights, please contact us at
                support@lawvocate.ai
              </li>
            </ul>
          </li>
          <li>
            <h6>{t("User Conduct")}</h6>
            {/* <p>{t("Electronic_Signatures_and_Agreements")}</p> */}
            <ul>
              <li>
                You agree not to engage in prohibited activities, including but
                not limited to:
                <ul>
                  <li>
                    Impersonating any person or entity or falsely stating or
                    otherwise misrepresenting your affiliation with a person or
                    entity
                  </li>
                  <li>
                    Using the Services for any unlawful purpose, including but
                    not limited to the violation of intellectual property laws
                    or data privacy laws.
                  </li>
                  <li>
                    Engaging in activity that is harmful to us or our users,
                    including but not limited to transmitting malware, viruses,
                    or harmful code.
                  </li>
                  <li>
                    Collecting, storing, or sharing personal information about
                    other users without their express permission
                  </li>
                  <li>
                    Interfering with or disrupting the Services or servers or
                    networks connected to the Services
                  </li>
                  <li>
                    Attempting to gain unauthorized access to the Services or
                    any systems or networks connected to the Services
                  </li>
                  <li>
                    Using the Services to harass, abuse, or harm another person
                  </li>
                  <li>
                    Using automated systems such as robots, spiders, or scrapers
                    without our prior written approval.
                  </li>
                  <li>
                    Engaging in spamming, phishing, or other predatory
                    practices.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <h6>{t("Compliance with Laws")}</h6>
            {/* <p>{t("Governing_Law_and_Jurisdiction")}</p> */}
            <ul>
              <li>
                The Services are based in the United States and are intended to
                be used only by residents of the United States. Given the global
                nature of the Internet, however, the Services may be accessed by
                users residing outside of the United States. We make no
                representation that the Services are appropriate or available
                for use outside of the United States. If you use the Services
                outside the United States, you are responsible for compliance
                with local laws. You also acknowledge and agree that your use of
                the Services will comply with all applicable laws and
                regulations, including but not limited to the General Data
                Protection Regulation ("GDPR") for users in the European Union
                and the California Consumer Privacy Act ("CCPA") for users in
                California
              </li>
            </ul>
          </li>
          <li>
            <h6> {t("Disclaimers")}</h6>
            {/* <p>{t("Severability")}</p> */}
            <ul>
              <li>
                Your use of the Websites and Services is also governed by our
                Disclaimer, which includes important information regarding the
                limitations of our AI and the nature of the information provided
                on our Websites. The full Disclaimer can be found here
              </li>
              <li>
                No Legal Advice: The content provided on the Websites, including
                but not limited to information generated by our AI agent, is for
                informational purposes only and does not constitute legal
                advice. The AI should not be considered a substitute for
                consultation with a licensed attorney.
              </li>
              <li>
                No Attorney-Client Relationship: Your use of the Websites,
                including the submission of queries or receipt of responses from
                our AI agent, does not create an attorney-client relationship
                between you and the Company or any of its representatives
              </li>
              <li>
                Limitation of Liability & No Warranties: Please refer to the
                Disclaimer for full details on how we limit our liability and
                warranties regarding the use of our Services and Websites
              </li>
            </ul>
          </li>
          <li>
            <h6> {t("Electronic Signatures and Agreements")}</h6>
            {/* <p>{t("No_Waiver")}</p> */}
            <ul>
              <li>
                You acknowledge and agree that by clicking on the 'I Accept'
                button or a similar button or checkbox as may be designated by
                the Company to show your approval of any foregoing texts, you
                are entering into a legally binding contract. You hereby agree
                to the use of electronic communication in order to enter into
                contracts, place orders, and create other records and to the
                electronic delivery of notices, policies, and records of
                transactions initiated or completed through the Services.
                Furthermore, you waive any rights or requirements under any laws
                or regulations in any jurisdiction requiring an original
                (non-electronic) signature or delivery or retention of non-
                electronic records, to the extent permitted under applicable
                mandatory law.
              </li>
            </ul>
          </li>

          <li>
            <h6> {t("Amendments and Updates")}</h6>
            {/* <p>{t("No_Waiver")}</p> */}
            <ul>
              <li>
                We reserve the right to amend this Agreement at any time. We
                will post the amended terms on the Website. Your continued use
                of the Services following the posting of changes constitutes
                your acceptance of such changes
              </li>
            </ul>
          </li>

          <li>
            <h6> {t("Governing Law and Dispute Resolution")}</h6>
            {/* <p>{t("No_Waiver")}</p> */}
            <ul>
              <li>
                Governing Law: These Terms shall be governed by and construed in
                accordance with the laws of the State of Michigan, USA, without
                regard to its conflict of law provisions
              </li>
              <li>
                Dispute Resolution Process: Any dispute arising out of or
                relating to these Terms will be resolved by binding arbitration
                in accordance with the rules of the American Arbitration
                Association ("AAA"). The arbitration shall be conducted in Grand
                Haven, Michigan, USA
              </li>
            </ul>
          </li>

          <li>
            <h6> {("Severability")}</h6>
            {/* <p>{t("No_Waiver")}</p> */}
            <ul>
              <li>
                If any provision of these Terms is held to be invalid or
                unenforceable, it shall be struck, and the remaining provisions
                shall remain in full force and effect
              </li>
            </ul>
          </li>

          <li>
            <h6> {t("No Waiver")}</h6>
            {/* <p>{t("No_Waiver")}</p> */}
            <ul>
              <li>
                No waiver of any term of this Agreement shall be deemed a
                further or continuing waiver of such term or any other term, and
                the Company's failure to assert any right or provision under
                this Agreement shall not constitute a waiver of such right or
                provision
              </li>
            </ul>
          </li>

          <li>
            <h6> {t("Entire Agreement")}</h6>
            {/* <p>{t("No_Waiver")}</p> */}
            <ul>
              <li>
                These Terms, along with the Privacy Policy and Disclaimer,
                constitute the entire agreement between you and LawvocateAI
                regarding the Services and supersede all prior or
                contemporaneous communications and proposals, whether oral or
                written
              </li>
            </ul>
          </li>

          <li>
            <h6> {t("Miscellaneous")}</h6>
            {/* <p>{t("No_Waiver")}</p> */}
            <ul>
              <li>
                Compliance with UETA: You agree that your electronic signature
                and records created through the Services are legally binding and
                have the same effect as written and paper signatures
              </li>
            </ul>
          </li>

          <li>
            <h6> {t("Acknowledgment")}</h6>
            {/* <p>{t("No_Waiver")}</p> */}
            <ul>
              <li>
                IF YOU DO NOT AGREE TO ALL OF THE FOREGOING, YOU MAY NOT USE OR
                ACCESS THE SERVICES IN ANY MANNER
              </li>
            </ul>
          </li>
        </ol>
        <hr />
        {/* <h6>{t("UseAccessConditions")}</h6>
        <h6> {t("IntroductionAcceptance")}</h6>
        <p>{t("Introduction_and_Acceptance")}</p>
        <p>{t("Acceptance")}</p>
        <h6>{t("UserInformationConsent")}</h6> */}
        {/* <p>
          <strong>
            {" "}
            {t("DataCollectionUse")}: We collect the following personal data:{" "}
          </strong>
        </p> */}
        {/* <ul>
          <li>{t("ProvideDirectlyInformation")}</li>
          <li>
            {t("CollectedAutomaticallyInformation")}
            {t("DataUsage")}
          </li>
          <li> {t("ServiceProvision")}</li>
          <li>{t("MarketingCommunications")}</li>
          <li>{t("LegalCompliance")}</li>
          <li>{t("Contract")}</li>
          <li>{t("LegitimateInterest")}</li>
          <li>{t("Consent")}</li>
        </ul> */}
        <p>
          <strong>Effective Date: June 21, 2024</strong>
        </p>

        <p>
          <strong>
            Copyright © 2024 LawvocateAI LLC. All rights reserved.
          </strong>
        </p>
        {/* <ul>
          <li>{t("BasisContract")}</li>
          <li>{t("BasisLegitimateInterest")}</li>
          <li>{t("BasisConsent")}</li>
        </ul> */}
        <hr />

        <p>
          <strong>
            If you need any adjustments or further inclusions, feel free to let
            me know
          </strong>
        </p>
        {/* <p>
          <strong>{t("UserRights")}</strong>
        </p> */}
        {/* <ul>
          {" "} */}
        {/* {t("Acceptance_of_Terms")} */}
        {/* <li>{t("RightAccess")}</li>
          <li>{t("RightRectification")}</li>
          <li>{t("RightErasure")}</li>
          <li>{t("Right_to_restriction")}</li>
          <li>{t("RightDataPortability")}</li>
          <li>{t("RightObject")}</li>
        </ul>
        <p>{t("ExerciseRightsContact")}</p>
        <p>{t("Consent_for_Data_Processing")}</p>
        <h6> {t("ComplianceWithUETA")}</h6>
        <p>{t("Compliance_with_UETA")}</p>
        <h6> {t("AmendmentsUpdates")}</h6>
        <p>{t("Amendments_and_Updates")}</p>
        <h6> {t("DisputeResolution")}</h6>
        <p>{t("Dispute_Resolution")}</p>
        <h6> {t("Miscellaneous")}</h6>
        <p>{t("MiscellaneousDetail")}</p>
        <h6>{t("NonAgreementWarning")}.</h6>
        <h6>{t("LegalBasisProcessing")}</h6> */}

        {/* <h6 className="pt-5 text-center">
          Copyright © {currentYear} LawvocateAI LLC. All rights reserved.
        </h6> */}
      </div>
      <Footer />
    </>
  );
}
export default TermsOfServices;
