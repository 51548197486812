import React, { useEffect } from "react";
import Herosection2img from "../../assets/images/testtt.png";
import "../hero2/hero2.scss";
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";
const AboutUs = ({ isB2C }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="hero2Section-main">
        <Navbar />
        <div className="herosec-primary  container">
          <div className="herosection-content">
            <div className="leftside">
              <div className="leftContent">
                <h5>About Us{isB2C} </h5>
                {isB2C ? (
                  <p>
                    Unlock Your Growth with
                    <a href="https://lawvocate.ai"> www.lawvocate.ai </a>
                    <br /> <br />
                    Welcome to Lawvocate.AI, your AI-powered business and legal
                    superpower. We are dedicated to empowering small businesses,
                    startups, and entrepreneurs with cutting-edge AI tools that
                    streamline legal and business processes. Our mission is to
                    make high-quality legal and business advice accessible and
                    affordable.
                    <a href="https://lawvocate.ai"> www.lawvocate.ai</a>, we
                    believe that every business deserves the best tools to
                    succeed. Our AI-driven platform offers various services,
                    from contract generation to business and marketing advice,
                    designed to help you grow and thrive. Join us on this
                    journey and unlock your full potential with
                    <a href="https://lawvocate.ai"> www.lawvocate.ai</a>.
                  </p>
                ) : (
                  <p>
                    Unlock Your Growth with
                    <span className="website"> lawvocate.ai. </span>
                    <br /> <br />
                    "Welcome to <span className="website">
                      {" "}
                      lawvocate.ai.{" "}
                    </span>{" "}
                    ", your AI-powered business and legal superpower. We
                    specialize in providing businesses with white-labeled AI
                    tools that enhance their service offerings. Our mission is
                    to empower businesses to deliver high-quality legal and
                    business advice to their clients efficiently and affordably.
                    <br /> <br />
                    At <span className="website"> lawvocate.ai. </span>,"We
                    understand the unique needs of businesses and offer
                    customizable solutions that integrate seamlessly into your
                    existing platforms. Our AI-driven tools are designed to help
                    you provide exceptional service to your clients, streamline
                    operations, and drive growth."
                  </p>
                )}
              </div>
            </div>
            <div className="rightside">
              <div className="rightcontent">
                <img src={Herosection2img} alt="img" />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AboutUs;
