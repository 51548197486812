import React, { useState } from "react";
import Cardlogo from "../../../assets/images/smalltag.png";
import Cardblacklogo from "../../../assets/images/cardblacklogo.png";
import Tick from "../../../assets/images/tick.svg";
import BlackTick from "../../../assets/images/blacktick.svg";
import useLocalStorage from "use-local-storage";
import { useTranslation } from "react-i18next";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";

import { loadStripe } from "@stripe/stripe-js";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { toast } from "react-toastify";
import Subscriptiontabs from "./subscriptiontabs/subscriptiontabs";
const stripePromise = loadStripe(
  "pk_test_51OFrhCA8Z7XYs2EGhmpaLrnsI6NLc0u34OqER9RF0sO9R5SNeWioaqFyEnmzVRjBf0SOx7PPeT1IVVLk41vdgaJu00etitUgAj"
);
const Dashboardplan = (props) => {
  const { t } = useTranslation();
  const [clientSecret, setClientSecret] = useState(null);

  const stripe = useStripe();





  const preference = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [isDark, setIsDark] = useLocalStorage("isDark", preference);
  return (
    <>
      <div className="dashboardplan">
        <section className={`plans-sections ${props.className}`}>
          <Subscriptiontabs />
        </section>
      </div>
    </>
  );
};

const WrappedStripePaymentForm = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <Dashboardplan className={props.className} />
    </Elements>
  );
};
// export default Plans;
export default WrappedStripePaymentForm;
// export default Dashboardplan;
