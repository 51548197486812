import React from "react";
import "./advice.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ChangeLanguageButton } from "../../i18n/changeLanguage";

const Advice = (props) => {
  const { t } = useTranslation();

  const {
    img,
    theading,
    tsubheading,
    headingLeft,
    subheadingLeft,
    headingright,
    subheadingright,
    expertheading,
    expertsubheading,
  } = props;

  const navigate = useNavigate();
  return (
    <>
      <div className="advice container">
        <div className="advice-content d-none">
          <div className="leftside">
            <div className="leftside-content">
              <div className="header">
                <h5>{theading}</h5>
                <p>{tsubheading}</p>
              </div>
              <div className="middle-section">
                <div className="middle-left">
                  <h5>{headingLeft}</h5>
                  <p>{subheadingLeft}</p>
                </div>
                <div className="middle-right">
                  <h5>{headingright}</h5>
                  <p>{subheadingright}</p>
                </div>
              </div>
              {/* <button>Learn more</button> */}
            </div>
          </div>
          <div className="rightside">
            <div className="rightside-content">
              <img src={img} alt="advice img" />
            </div>
          </div>
        </div>
        <div className="expert-content">
          <div className="expertleft-content">
            <div className="expert-primary">
              <h5>{expertheading}</h5>
            </div>
          </div>
          <div className="expertright-content">
            <div className="right-primary">
              <p>{expertsubheading}</p>
              <button onClick={() => navigate("/dashboard")}>
                {t("Empower_You")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Advice;
