import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { socketInstance } from "../socket";

const renderEndpoint = "https://lawvocate-backend.onrender.com";
const ngrokEndpoint =
  "https://0415-2407-d000-1a-d56e-5914-aa3c-5ac0-8e90.ngrok-free.app";

const apiLink = renderEndpoint;
const domainLink = "https://www.lawvocate.ai"

const signUpAPI = (userName, userEmail, userPassword, userConfirmPassword) => {
  return new Promise((resolve, reject) => {
    const apiUrl = `${apiLink}/signup`;
    const body = {
      username: userName,
      email: userEmail,
      password: userPassword,
      confirm_password: userConfirmPassword,
      domain_link: domainLink

    };

    axios
      .post(apiUrl, body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        // console.log("err:", err);
        reject(err);
      });
  });
};

const signInAPI = (userEmail, userPassword) => {
  return new Promise((resolve, reject) => {
    const apiUrl = `${apiLink}/login`;
    const body = {
      email: userEmail,
      password: userPassword,
      domain_link: domainLink
    };

    // console.log("bodyYyyyyyy::", body);

    axios
      .post(apiUrl, body)
      .then((res) => {
        // console.log("login api::", res);
        secureLocalStorage.setItem("isLogin", {
          email: userEmail,
          password: userPassword,
        });
        resolve(res);
      })
      .catch((err) => {
        // console.log("err:", err);
        reject(err.response);
      });
  });
};

const getLastSigninDate = (userEmail) => {
  return new Promise((resolve, reject) => {
    const apiUrl = `${apiLink}/last_login`;
    const body = {
      email: userEmail,
      domain_link: domainLink

    };

    // console.log("bodyYyyyyyy::", body);

    axios
      .post(apiUrl, body)
      .then((res) => {
        // console.log("login api::", res);
        // secureLocalStorage.setItem("isLogin", {
        //   email: userEmail,
        //   password: userPassword,
        // });
        resolve(res);
      })
      .catch((err) => {
        // console.log("err:", err);
        reject(err.response);
      });
  });
};

const chatApi = (
  prompt,
  email,
  chatCreation,
  chatId,
  messagetime,
  response,
  uniqueTextId,
  assistantid,
  fileUrl
) => {
  return new Promise((resolve, reject) => {
    const apiUrl = `${apiLink}/chat`;
    const accessToken = secureLocalStorage.getItem("loginData");
    const newSid = secureLocalStorage.getItem("sid");
    console.log("sid in apis", newSid);

    const headers = {
      "x-access-token": accessToken.token,
    };

    const body = {
      email: email,
      query: prompt,
      chatCreationTime: chatCreation,
      messageTimeStamp: messagetime,
      chatID: chatId,
      regenerateResponse: response,
      userId: uniqueTextId,
      assistantId: assistantid,
      fileUrl: fileUrl,
      // userid: userID,
      sid: newSid,
      domain_link: domainLink
    };

    console.log(body);
    console.log("message api body:", body);

    axios
      .post(apiUrl, body, {
        // headers: headers,
        timeout: 120000,
      })
      .then((response) => {
        console.log("response in axios:", response);
        resolve(response);
      })
      .catch((err) => {
        console.log("err in chat prompt:", err);
        reject(err);
      });
  });
};

const forgotPasswordApi = (userEmail) => {
  return new Promise((resolve, reject) => {
    const apiUrl = `${apiLink}/forgot_password`;
    const body = {
      email: userEmail,
      domain_link: domainLink
    };

    axios
      .post(apiUrl, body)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.log("err in chat prompt:", err);
        reject(err);
      });
  });
};

const resendOtpdApi = (userEmail) => {
  return new Promise((resolve, reject) => {
    const apiUrl = `${apiLink}/resend_otp`;
    const body = {
      email: userEmail,
      domain_link: domainLink
    };

    axios
      .post(apiUrl, body)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        console.log("err in chat prompt:", err);
        reject(err);
      });
  });
};

const resetPasswordApi = (
  userEmail,
  userCode,
  userNewPassword,
  userConfirmPassword
) => {
  return new Promise((resolve, reject) => {
    const apiUrl = `${apiLink}/reset_password`;
    const body = {
      email: userEmail,
      code: userCode,
      new_password: userNewPassword,
      confirm_password: userConfirmPassword,
      domain_link: domainLink
    };

    axios
      .post(apiUrl, body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        // console.log("err:", err);
        reject(err.response);
      });
  });
};

const verifyRecoveryCodeApi = (userEmail, verifyCode) => {
  return new Promise((resolve, reject) => {
    const apiUrl = `${apiLink}/verify_recovery_code`;
    const body = {
      email: userEmail,
      code: verifyCode,
      domain_link: domainLink
    };

    axios
      .post(apiUrl, body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        // console.log("err:", err);
        reject(err.response);
      });
  });
};

const allChatsApi = () => {
  const loginEmail = secureLocalStorage.getItem("loginData");

  return new Promise((resolve, reject) => {
    const apiUrl = `${apiLink}/allChats`;
    const body = {
      userEmail: loginEmail.email,
      domain_link: domainLink
    };

    axios
      .post(apiUrl, body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        // console.log("err:", err);
        reject(err.response);
      });
  });
};

const deleteChatApi = (chatId) => {
  const loginEmail = secureLocalStorage.getItem("loginData");

  return new Promise((resolve, reject) => {
    const apiUrl = `${apiLink}/delete_chat`;
    const body = {
      email: loginEmail.email,
      chatID: chatId,
      domain_link: domainLink
    };

    axios
      .post(apiUrl, body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        // console.log("err:", err);
        reject(err.response);
      });
  });
};

const contactApi = (firstName, LastName, number, email, message) => {
  return new Promise((resolve, reject) => {
    const apiUrl = `${apiLink}/contactus`;
    const body = {
      first_name: firstName,
      last_name: LastName,
      phone_no: number,
      email: email,
      description: message,
    };
    console.log(firstName, LastName, number, email, message);
    axios
      .post(apiUrl, body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        // console.log("err:", err);
        reject(err.response);
      });
  });
};

const uploadApi = (url) => {
  return new Promise((resolve, reject) => {
    const apiUrl = `${apiLink}/upload`;
    const body = {
      file: url,
    };

    axios
      .post(apiUrl, url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        // console.log("err:", err);
        reject(err.response);
      });
  });
};

const deleteUserAccountAPI = async () => {
  try {
    const loginEmail = await secureLocalStorage.getItem("loginData");
    const apiUrl = `${apiLink}/delete_account`;
    const email = {
      email: loginEmail.email,
      domain_link: domainLink
    };
    //console.log("email:", email);
    const response = await axios.delete(apiUrl, { data: email });
    return response;
  } catch (error) {
    throw error.response;
  }
};
const getUserProfileAPI = async () => {
  try {
    const loginEmail = await secureLocalStorage.getItem("loginData");
    const apiUrl = `${apiLink}/get_user_profile`;
    const email = {
      email: loginEmail.email,
      domain_link: domainLink
    };
    //console.log(email);
    const res = await axios.post(apiUrl, email);
    // console.log("res: ", res);
    return res;
  } catch (err) {
    //console.log("error", err.message);
  }
};
const updateUserProfileAPI = async (profile) => {
  try {
    const apiUrl = `${apiLink}/user_profile`;
    //console.log("email:", profile);
    const res = await axios.post(apiUrl, {...profile, domain_link: domainLink});
    //console.log("res:", res);
    return res;
  } catch (err) {
    //console.log(err.message);
  }
};

const verifySignUpOTP = (userEmail, verifyCode) => {
  return new Promise((resolve, reject) => {
    const apiUrl = `${apiLink}/verify_otp`;
    const body = {
      email: userEmail,
      otp: verifyCode,
      domain_link: domainLink
    };

    axios
      .post(apiUrl, body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        // console.log("err:", err);
        reject(err.response);
      });
  });
};
export {
  signUpAPI,
  signInAPI,
  chatApi,
  forgotPasswordApi,
  resetPasswordApi,
  verifyRecoveryCodeApi,
  allChatsApi,
  deleteChatApi,
  contactApi,
  uploadApi,
  deleteUserAccountAPI,
  getUserProfileAPI,
  updateUserProfileAPI,
  verifySignUpOTP,
  resendOtpdApi,
  getLastSigninDate
};
