import React, { useState } from "react";
import "./chatagent.scss";
// import Agentcard from "./agentcard";
import Consultimg from "../../assets/images/Group 188.svg";
import Experiencehassle from "../../assets/images/Group 189.svg";
import Unlockimg from "../../assets/images/Group 190.svg";
// import { InlineWidget, PopupWidget, PopupModal } from "react-calendly";
import { Modal } from "antd";
import { InlineWidget } from "react-calendly";
import Agentcard from "./agentcard";
import TidycalEmbed from "../embedtidycal/embedtidycal";
import { useTranslation } from "react-i18next";

const Chatagent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();

  const handleCalendly = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <div className="chatagent ">
        <div className="chatagent-content container ">
          <div className="chat-heading">
            <h5>
              {/* Discover the Power of Our Chat Agent for Expert Consultancy Advice
               */}
              {t("scheduleAppointments")}
            </h5>
            <p className="text-center mt-3">
              {/* Discover the Power of Our Chat Agent for Expert Consultancy Advice
               */}
              {t("schedule")}
            </p>

            {/* <InlineWidget url="https://calendly.com/hamzashah9273/30min" />
             */}
          </div>
          <div className="agent-cards gap-4 ">
            <Agentcard
              className="d-flex justify-content-center"
              img={Consultimg}
              heading={t("liveExpertsCall")}
              subheading={t("scheduleWithOurTeam")}
            />
            <Agentcard
              img={Experiencehassle}
              className="d-flex justify-content-center"
              heading={t("help")}
              subheading={t("chatbotFAQs")}
            />
            <Agentcard
              img={Unlockimg}
              className="d-flex justify-content-center"
              heading={t("documents")}
              subheading={t("needHelpWithDocument")}
            />
          </div>
          <div className="last-btn">
            <button onClick={handleCalendly}>{t("scheduleLiveHelp")}</button>
          </div>
        </div>
      </div>

      <Modal
        // title="Basic Modal"
        open={isModalOpen}
        onOk={handleCalendly}
        onCancel={handleCalendly}
        footer={null}
        width="1400px"
      >
        <div className="calendly-wrapper">
          {/* <InlineWidget url="https://calendly.com/hamzashah9273/30min" /> */}
          <TidycalEmbed path="markunderwood" />
        </div>
      </Modal>
    </>
  );
};

export default Chatagent;
