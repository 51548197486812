import React, { useState, useEffect, useRef } from "react";
import Hero2 from "../hero2/hero2";
import Advice from "../advice/advice";
import Advicecard from "../advicecard/advicecard";
import Chatagent from "../chatagent/chatagent";
import Pricingtab from "../pricingtab/pricingtab";
import Footer from "../footer/footer";
import secureLocalStorage from "react-secure-storage";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Frequently from "../frequently/frequently";

const Home2 = () => {
  const { t } = useTranslation();
  const [showFirstModal, setShowFirstModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [cookiesAccepted, setCookiesAccepted] = useState(false);
  const [acceptedPermanently, setAcceptedPermanently] = useState(false);
  // const navigate = useNavigate();
  // const modalRef = useRef(null);
  // if (!modalRef) {
  //   toast.error("Please check first");
  // }
  // const handleCloseFirstModal = () => {
  //   if (termsChecked && privacyChecked) {
  //     setShowFirstModal(false);
  //     setShowSecondModal(true);
  //   } else {
  //     toast.error("Accept Terms and Privacy policy", {
  //       toastId: "Agree error",
  //     });
  //   }
  // };

  // const handleCloseSecondModal = () => {
  //   if (cookiesAccepted) {
  //     setShowSecondModal(false);
  //     setAcceptedPermanently(true); // Update state and store to indicate permanent acceptance
  //     localStorage.setItem("acceptedPermanently", "true");
  //   } else {
  //     setShowFirstModal(true);
  //     toast.error("Accept Terms and Privacy policy", {
  //       toastId: "second modal",
  //     });
  //   }
  // };

  // const handleAcceptCookies = () => {
  //   setCookiesAccepted(true);
  //   setShowSecondModal(false);
  //   setAcceptedPermanently(true); // Update state and store to indicate permanent acceptance
  //   localStorage.setItem("acceptedPermanently", "true"); // Close the modal after accepting cookies
  // };

  // const handleTermsChange = () => {
  //   setTermsChecked(!termsChecked);
  // };

  // const handlePrivacyChange = () => {
  //   setPrivacyChecked(!privacyChecked);
  // };

  // useEffect(() => {
  //   const loginData = secureLocalStorage.getItem("loginData");
  //   const accepted = localStorage.getItem("acceptedPermanently");

  //   if (loginData) {
  //     navigate("/dashboard");
  //   }
  //   if (accepted) {
  //     setAcceptedPermanently(true);
  //     setShowFirstModal(false);
  //     setShowSecondModal(false);
  //   } else {
  //     setShowFirstModal(true);
  //   }

  //   // localStorage.clear();
  // }, []);
  // const notifyError = (message) => {
  //   toast.error(message);
  // };
  return (
    <div style={{ background: "#F2F2F2" }}>
      <Hero2 />
      <Advice
        expertheading={t("unlockBusinessPotential")}
        expertsubheading={t("chatAgentServices")}
      />
      <div className="mt-5 pt-5">
        <Advicecard />
      </div>
      <Chatagent />
      <div className="pb-3">
        <Pricingtab />
      </div>
      <div className="mb-3">
        <Frequently />
      </div>
      <Footer />
      {/* <ToastContainer /> */}

      {/* First Modal */}
      {/* <Modal
        show={showFirstModal}
        onHide={handleCloseFirstModal}
        className="termsmodal"
        size="xl"
        centered
        ref={modalRef}
      >
        <Modal.Body className="termsmodal-body">
          <div className="terms-modal">
            <div className="terms-header">
              <span>{t("termsOfService")}</span>
            </div>
            <div className="middle-body h-auto">
              <div className="middle-top">
                <p>{t("LawvocateAIDisclaimer")}</p>
                <p>
                  {t("expressAgreementRequirement")}
                  <span>{t("PrivacyPolicy")}</span>
                  {t("and")}
                  <span>{t("termsOfUse")}</span>.{t("confirmUnderstanding")}
                </p>
              </div>
            </div>
            <div className="checkbox-termsmodal">
              <div className="form-check formcheck-parent">
                <input
                  className="form-check-input formcheckinput"
                  type="checkbox"
                  id="tos"
                  checked={termsChecked}
                  onChange={handleTermsChange}
                />
                <label
                  className="form-check-label formchecklabel"
                  htmlFor="tos"
                >
                  <strong>{t("termsOfService")}</strong>
                </label>
                <a href="/termsofservice">(See Terms and conditions)</a>
              </div>
              <div className="form-check formcheck-parent">
                <input
                  className="form-check-input formcheckinput"
                  type="checkbox"
                  id="pp"
                  checked={privacyChecked}
                  onChange={handlePrivacyChange}
                />
                <label className="form-check-label formchecklabel" htmlFor="pp">
                  <strong> {t("PrivacyPolicy")}</strong>
                </label>
                <a href="/privacy-policy">(See Privacy Policy)</a>
              </div>
            </div>

            <div className="term-botton">
              <button
                className="agree"
                onClick={handleCloseFirstModal}
                // disabled={!(termsChecked && privacyChecked)}
              >
                Agree
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}

      {/* Second Modal */}
      {/* <Modal
        show={showSecondModal}
        onHide={handleCloseSecondModal}
        className="termsmodal cookies-modal"
        size="lg"
        centered
        backdrop="static" // Prevent closing when clicking outside
        keyboard={false} // Prevent closing with the escape key
        ref={modalRef}
      >
        <Modal.Body className="termsmodal-body cookies-modal-body">
          <div className="terms-modal">
            <div className="middle-body h-auto">
              <p className="text-center">{t("cookiesConsentMessage")}</p>
            </div>
            <div className="term-botton">
              <Button className="accept-cookies" onClick={handleAcceptCookies}>
                {t("acceptCookies")}
              </Button>
            
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
    </div>
  );
};

export default Home2;
