import React from "react";
import "./price.scss";
import Cardtick from "../../assets/images/cardtick.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const YearlyPrice = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <div className="price-card">
        <h3>{t("annual2monthsFree")}</h3>

        <div className="card-content ">
          <div className="first-card">
            <div className="card-title">
              <span>{t("plan")}</span>
              <p>{t("planPara")}</p>
            </div>
            <div className="card-month">
              <div className="month-heading">
                <span className="dollar">{t("Price")}</span>
                {/* <span className="month">{t("3days")}</span> */}
              </div>
            </div>
            <div className="card-bottom">
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("Access")} </span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("Client_Limit")}</span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("Support")} </span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("Trial_Period")} </span>
                </div>
              </div>
            </div>
            <div className="card-button">
              <button
                onClick={() =>
                  navigate("/signin", { state: { forPlan: true } })
                }
              >
                {t("getStarted")}
              </button>
            </div>
          </div>
          {/* <div className="first-card">
            <div className="card-title">
              <span>{t("basic")}</span>
              <p>{t("proPara")}</p>
            </div>
            <div className="card-month">
              <div className="month-heading">
                <span className="dollar">$25{t("month")}</span>
                <span className="month">{t("7days")}</span>
              </div>
            </div>
            <div className="card-bottom">
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("the_rest_listed")}</span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("Unlimited_AI")}</span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("Live_chat_support")}</span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("Priority_access")}</span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("Free_monthly")}</span>
                </div>
              </div>
               
            </div>
            <div className="card-button">
              <button
                onClick={() =>
                  navigate("/signin", { state: { forPlan: true } })
                }
              >
                {t("getStarted")}
              </button>
            </div>
          </div> */}
          <div className="first-card">
            <div className="card-title">
              <span>Pro Plan</span>
              <p>{t("proPara")}</p>
            </div>
            <div className="card-month">
              <div className="month-heading">
                <span className="dollar"> {t("proPrice")}</span>
                <span className="month">
                  {/* {t("Month")} */}
                  {t("pro_Trial_Period")}
                </span>
              </div>
            </div>
            <div className="card-bottom">
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("pro_Client_Limit")}</span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("pro_Support")} </span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span> {t("priorityAccessToAttorneys")}</span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("Custom_Branding")} </span>
                </div>
              </div>
              <div className="bottom-primary">
                <div className="left">
                  <img src={Cardtick} alt="" />
                </div>
                <div className="right">
                  <span>{t("Customer_Service")} </span>
                </div>
              </div>
            </div>
            <div className="card-button">
              <button
                onClick={() =>
                  navigate("/signin", { state: { forPlan: true } })
                }
              >
                {t("getStarted")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default YearlyPrice;
