import React, { useEffect, useState } from "react";
import "./hero2.scss";
import Herosection2img from "../../assets/images/testtt.png";
import { Icon } from "@iconify/react";
import Navbar from "../navbar/navbar";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Hero2 = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const { t } = useTranslation();
  const [banner, setBanner] = useState(true);
  const [showBanner, setShowBanner] = useState(true);
  const handleBannerClose = () => {
    setShowBanner(false);
    localStorage.setItem("bannershow", true);
  };

  useEffect(() => {
    const bannerShow = localStorage.getItem("bannershow");
    console.log("Banner", bannerShow);
    setBanner(bannerShow);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        "https://api.hsforms.com/submissions/v3/integration/submit/45212400/7a6f6641-2b86-4476-a809-f0876bccc692",
        {
          fields: [
            {
              name: "email",
              value: email,
            },
          ],
          context: {
            pageUri: window.location.href,
            pageName: document.title,
          },
        }
      );
      console.log(res);
      setEmail("");
      setMessage("Thank you for subscribing!");
    } catch (err) {
      setMessage("There was an error. Please try again.");
    }
  };

  return (
    <>
      {" "}
      {/* <div className="newsletter-banner">
        <span className="newsletter-bannertext">test</span>
        <span>
          <Icon
            icon="entypo:cross"
            width="30"
            height="30"
            style={{ color: "white", cursor: "pointer" }}
          />
        </span>
      </div> */}
      {banner == null ? (
        showBanner && (
          <div className="newsletter-banner">
            <div className="leftside-newsletter">
              <div className="newsletter-form">
                <span className="newsletter-bannertext">
                  {t("getFreeInsights")}
                </span>
                <form onSubmit={handleSubmit}>
                  <input
                    type="email"
                    inputMode="email"
                    required
                    value={email}
                    placeholder={t("enterEmailAddress")}
                    className="email-newsletter"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </form>
              </div>
              <div>
                <button className="subscribe-newsletter" type="submit">
                  {t("subscribeNewsletter")}
                </button>
              </div>
            </div>
            <div className="rightside-newsletter">
              {" "}
              <span className="newsletter-bannertext">
                {" "}
                <span>
                  <Icon
                    icon="entypo:cross"
                    width="30"
                    height="30"
                    style={{ color: "white", cursor: "pointer" }}
                    onClick={handleBannerClose}
                  />
                </span>
              </span>
            </div>
          </div>
        )
      ) : (
        <></>
      )}
      <div className="hero2Section-main">
        <Navbar />
        <div className="herosec-primary container">
          <div className="herosection-content">
            <div className="leftside">
              <div className="leftContent">
                <h5>
                  {/* Empowering companies through expert legal and{" "}
                  <span>business advice</span> */}
                  {t("empowerYour")} <span> {t("business")} </span>
                  {t("today")}
                </h5>
                <p>
                  {/* <strong className="text-black ">{t("benefits")}</strong> */}
                  {/* <br /> */}
                  {/* <ul className="mt-2 d-flex flex-column gap-3">
                    <li>{t("expertAdvice")}</li>
                    <li>{t("personalizedGuidance")}</li>

                    <li>{t("timeSavingSolutions")}</li>
                    <li>{t("stayAheadWithAI")}</li>
                  </ul> */}
                  {t("trustedPartner")}
                </p>
                {/* <button>Start</button> */}
              </div>
            </div>
            <div className="rightside">
              <div className="rightcontent">
                {/* <img src={Herosection2img} alt="img" /> */}
                <img src={Herosection2img} alt="img" />
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/images/herotopimg.png"
          alt="herobtmimg"
          className="herotopimg"
        />
        <img
          src="assets/images/herobtmimg.png"
          alt="herobtmimg"
          className="herobottomimg"
        />
      </div>
    </>
  );
};

export default Hero2;
