import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Logos from "../assets/images/Group 138.svg";
import { Icon } from "@iconify/react";
import { resetPasswordApi } from "../apis/api";
import useLocalStorage from "use-local-storage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Hourglass } from "react-loader-spinner";
const Resetpassword = () => {
  const [spinner, setSpinner] = useState(false);
  const preference = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [isDark, setIsDark] = useLocalStorage("isDark", preference);
  const { state } = useLocation();
  const historyData = state || {};
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmpassword, setconfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordconfirm, setShowPasswordconfirm] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmpasswordError, setconfirmPasswordError] = useState("");
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibilityconfirm = () => {
    setShowPasswordconfirm(!showPasswordconfirm);
  };
  const handleSignIn = () => {
    // Simple validation for email and password
 
    if (!password) {
      setPasswordError("Password is required");
      return;
    }
    if (!confirmpassword) {
      setconfirmPasswordError("ConfirmPassword is required");
      return;
    }
    if (password !== confirmpassword) {
      setconfirmPasswordError(" Password do not match");
      return;
    } else {
      // alert("new password updated.");
      setSpinner(true);
      resetPasswordApi(
        historyData.email,
        historyData.otp,
        password,
        confirmpassword
      )
        .then((response) => {
          setSpinner(false);
          notify("New Password Updated", "success");
          console.log("response", response);
          // navigate("/signin");
          navigate("/signin", {state: {showPasswordToast: true}});

        })
        .catch((error) => {
          setSpinner(false);
          console.log("error in  reset password:", error);
          notify("Error in  reset password:", "error");
        });
    }
    // Perform your sign-in logic here
    // navigate("/plan");
  };
  const notify = (message, type = "success") => {
    toast[type](message, {
      position: "top-right",
      autoClose: 2000,
    });
  };
  return (
    <>
      <div className="background-account" data-theme={isDark ? "dark" : ""}>
        <div className="resetPassword" data-theme={isDark ? "dark" : ""}>
          <div className="signin-content" data-theme={isDark ? "dark" : ""}>
            <div className="signup-logo">
              {/* <img src={Logo} alt="signuplogo" className="custom" /> */}
              <img src={Logos} alt="signuplogo" className="custom" />
              {/* <img src={Logoss} alt="signuplogo" className="custom" /> */}
            </div>
            <div className="signup-card" data-theme={isDark ? "dark" : ""}>
              <div
                className="signupcard-content"
                data-theme={isDark ? "dark" : ""}
              >
                <div className="main-card" data-theme={isDark ? "dark" : ""}>
                  <div className="cardtitle" data-theme={isDark ? "dark" : ""}>
                    <div className="leftside" data-theme={isDark ? "dark" : ""}>
                      <h6 data-theme={isDark ? "dark" : ""}>Reset Password</h6>
                    </div>
                    <div
                      className="rightside"
                      data-theme={isDark ? "dark" : ""}
                    >
                      <h6 data-theme={isDark ? "dark" : ""}>
                        {/* Set the new password for your account so <br /> you can
                      login and access all the features. */}
                        Enter a new password below to reset your <br /> old
                        password
                      </h6>
                    </div>
                  </div>
                  <div
                    className="accountRecovery-middle"
                    data-theme={isDark ? "dark" : ""}
                  >
                    <div
                      className="firstfield"
                      data-theme={isDark ? "dark" : ""}
                    >
                      <div className="label" data-theme={isDark ? "dark" : ""}>
                        <label htmlFor="" data-theme={isDark ? "dark" : ""}>
                          Password
                        </label>{" "}
                      </div>
                      <div
                        className="inputfield"
                        data-theme={isDark ? "dark" : ""}
                      >
                        <input
                          type={showPassword ? "text" : "password"}
                          placeholder="Password"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            setPasswordError(""); // Clear error when user starts typing
                          }}
                          data-theme={isDark ? "dark" : ""}
                        />
                        <div
                          className="eye-icon"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <Icon
                              icon="octicon:eye-24"
                              color="gray"
                              width="18"
                              height="18"
                            />
                          ) : (
                            <Icon
                              icon="tabler:eye-off"
                              color="gray"
                              width="18"
                              height="18"
                            />
                          )}
                        </div>
                      </div>
                      <span style={{ color: "red" }} className="span">
                        {passwordError}
                      </span>
                    </div>
                    <div
                      className="firstfield2"
                      data-theme={isDark ? "dark" : ""}
                    >
                      <div className="label" data-theme={isDark ? "dark" : ""}>
                        <label htmlFor="" data-theme={isDark ? "dark" : ""}>
                          Confirm Password
                        </label>{" "}
                      </div>
                      <div
                        className="inputfield"
                        data-theme={isDark ? "dark" : ""}
                      >
                        <input
                          type={showPasswordconfirm ? "text" : "password"}
                          placeholder="Password"
                          value={confirmpassword}
                          onChange={(e) => {
                            setconfirmPassword(e.target.value);
                            setconfirmPasswordError(""); // Clear error when user starts typing
                          }}
                          data-theme={isDark ? "dark" : ""}
                        />
                        <div
                          className="eye-icon"
                          onClick={togglePasswordVisibilityconfirm}
                        >
                          {showPasswordconfirm ? (
                            <Icon
                              icon="octicon:eye-24"
                              color="gray"
                              width="18"
                              height="18"
                            />
                          ) : (
                            <Icon
                              icon="tabler:eye-off"
                              color="gray"
                              width="18"
                              height="18"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <span style={{ color: "red" }} className="span">
                      {confirmpasswordError}
                    </span>
                  </div>

                  <div
                    className="card-bottom"
                    data-theme={isDark ? "dark" : ""}
                  >
                    <div
                      className="signin-btn"
                      data-theme={isDark ? "dark" : ""}
                    >
                      <button
                        onClick={handleSignIn}
                        data-theme={isDark ? "dark" : ""}
                      >
                        Continue
                        {spinner && (
                          <span>
                            {" "}
                            {/* <Spinner
                              animation="border"
                              variant="primary"
                              size="sm"
                            /> */}
                            <Hourglass
                              visible={true}
                              height="15"
                              width="15"
                              ariaLabel="hourglass-loading"
                              wrapperStyle={{}}
                              wrapperClass=""
                              colors={
                                isDark ? ["#000", "#000"] : ["#fff", "#fff"]
                              }
                              data-theme={isDark ? "light" : "dark"}
                            />
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Resetpassword;
