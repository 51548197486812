import React, { useEffect } from "react";
import Agentcard from "../chatagent/agentcard";
import { useTranslation } from "react-i18next";
import Ai from "../../assets/images/groupAi.png";
import Contract from "../../assets/images/groupContract.png";
import Business from "../../assets/images/groupBuisness.png";
import Market from "../../assets/images/groupMarket.png";
import planning from "../../assets/images/groupPlanning.png";
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";
const OurServices = () => {
  const { t } = useTranslation();
  const services = [
    {
      img: Ai,
      heading: t("White_Labeled_AI_Solutions"),
      subheading: t("White_Labeled_AI_Solutions_Para"),
    },
    {
      img: Contract,
      heading: t("Contract_Generation"),
      subheading: t("Contract_Generation_para"),
    },
    {
      img: Business,
      heading: t("Business_Advice"),
      subheading: t("Business_Advice_para"),
    },
    {
      img: Market,
      heading: t("Marketing_Advice"),
      subheading: t("Marketing_Advice_para"),
    },
    {
      img: planning,
      heading: t("Planning_and_Much_More"),
      subheading: t("Planning_and_Much_More_para"),
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <div className="chatagent pt-5">
        <div className="chatagent-content container pt-5">
          <div className="chat-heading">
            <h5>{t("Our Services")}</h5>
          </div>
          <div className="agent-cards">
            {services.map((service, index) => (
              <Agentcard
                key={index}
                img={service.img}
                heading={service.heading}
                subheading={service.subheading}
              />
            ))}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default OurServices;
