import React from "react";
import "./agentcard.scss";

const Agentcard = (props) => {
  const { img, heading, subheading, btntext, className } = props;
  return (
    <>
      <div className={`agentcard  ${className}`}>
        <div className="agentcard-content">
          <div className="title-img">
            <img src={img} alt="img" />
          </div>
          <div className="title">
            <p>{heading}</p>
          </div>

          <div className=" subtitle">
            <p>{subheading}</p>
          </div>
          <div className="card-btn">{/* <button>{btntext}</button> */}</div>
        </div>
      </div>
    </>
  );
};

export default Agentcard;
