import React from "react";
import Card from "./card";
import "./advicecard.scss";
import Legalservice from "../../assets/images/legal-service.png";
import Startupadvice from "../../assets/images/startup-advice.png";
import growthadvice from "../../assets/images/growth-strat.png";
import actionVideo from "../../assets/videos/updatedf2.mp4";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const Advicecard = (props) => {
  const [head, setHead] = useState(true);
  const { t } = useTranslation();
  return (
    <>
      <div className={`advicecard-section container ${props.className}`}>
        <div>
          <h5 style={{ textTransform: "none" }}>{t("meetLawvocateAI")}</h5>
          <p className="fw-normal advicecard-p">{t("watchVideo")}</p>
        </div>
        <div className="section-content">
          <div className="vedio">
            <video autoPlay loop muted playsInline>
              <source src={actionVideo} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </>
  );
};

export default Advicecard;
