import React, { useState } from "react";
import "./pricingtab.scss";
import Price from "./price";
import { useTranslation } from "react-i18next";
import YearlyPrice from "./yearlyPrice";

import { loadStripe } from "@stripe/stripe-js";
import {
  Elements

} from "@stripe/react-stripe-js";

const Pricingtab = (props) => {
  const [activeSection, setActiveSection] = useState("Monthly");
  const { t } = useTranslation();
  const stripePromise = loadStripe(
    "pk_test_51OFrhCA8Z7XYs2EGhmpaLrnsI6NLc0u34OqER9RF0sO9R5SNeWioaqFyEnmzVRjBf0SOx7PPeT1IVVLk41vdgaJu00etitUgAj"
  );
  const toggleContent = (section) => {
    setActiveSection(section);
  };
  return (
    <>
      <div className={`pricingtab ${props.className}`}>
        <div className="containers">
          <div className="title">
            <h5>{t("subscribeNow")}</h5>
          </div>
          <div className="subheading">
            <h5>{t("choosePlan")}</h5>
          </div>
          <div className="pricingtabs">
            <div className="pricingtabcontent">
              <div className="setingHeader">
                <div className="settinghContent">
                  <div className="toggle-container">
                    <button
                      className={`toggle-button ${
                        activeSection === "Monthly" ? "active" : ""
                      }`}
                      onClick={() => toggleContent("Monthly")}
                    >
                      {t("monthlyPlan")}
                    </button>
                    <button
                      className={`toggle-button ${
                        activeSection === "Yearly" ? "active" : ""
                      }`}
                      onClick={() => toggleContent("Yearly")}
                    >
                      {t("annualPlan")}
                      {/* <span className="good">{t("twoMonthsFree")}</span> */}
                    </button>
                  </div>

                  <div className="span-container">
                    {activeSection === "Monthly" && <Elements stripe={stripePromise}><Price /></Elements>}
                    {activeSection === "Yearly" && <YearlyPrice />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricingtab;
