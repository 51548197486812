import React, { useState, useEffect, useRef } from "react";
import { Icon } from "@iconify/react";
import "../style/dashboard.css";
import Logo from "../assets/images/Group 138.svg";
import Logo2 from "../assets/images/whitelogo.svg";
import Profile from "../assets/images/images.png";
import Newchat from "../components/dashboards/newchat/newchat";
import Modal from "react-bootstrap/Modal";
import Settings from "../components/dashboards/setings/settings";
import secureLocalStorage from "react-secure-storage";
import { useLocation, useNavigate } from "react-router-dom";
import { generateUniqueId, checkLoginExpiration } from "../constants/constants";
import useLocalStorage from "use-local-storage";
import {
  allChatsApi,
  deleteChatApi,
  deleteUserAccountAPI,
  getUserProfileAPI,
  updateUserProfileAPI,
} from "../apis/api";
import useDataStore from "../zustand/store";
import { notify } from "../constants/constants";
import { useTranslation } from "react-i18next";
import { socketInstance } from "../socket";
import { useIdleTimer } from "react-idle-timer";
import { toast } from "react-toastify";

const Dashboard = () => {
  const preference = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [userProfileData, setUserProfileData] = useState({
    full_name: "",
    email: "",
    phone_number: "",
    picture: "",
    description: "",
  });
  const [newProfileImg, setNewProfileImg] = useState(null);
  const uniqueId = generateUniqueId();
  const { data, iteration, concatenatedData } = useDataStore();
  const { i18n, t } = useTranslation();
  const [activeTab, setActiveTab] = useState("tab1");
  const [mobileWidth, setMobileWidth] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const [isDark, setIsDark] = useLocalStorage("isDark", preference);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [showPersonalInfo, setShowPersonalInfo] = useState(false);
  const [show, setShow] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isSocketOn, setIsSocketOn] = useState(true);
  const [chatList, setChatList] = useState([]);
  const [singleChatData, setSingleChatData] = useState({
    chatID: uniqueId,
    chatCreationTime: new Date().getTime(),
    chatData: [],
  });
  const [isPictureChanged, setIsPictureChanged] = useState(false)
const location = useLocation();
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (e) => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  ///////////////////////////////////
  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const res = await getUserProfileAPI();
        if (res && res.data) {
          setUserProfileData({
            email: res.data.email,
            picture: res.data.picture,
            full_name: res.data.full_name,
            phone_number: res.data.phone_number,
            description: res.data.description,
          });
          setNewProfileImg(res.data.picture);
        } else {
          console.error("Response data is undefined or empty");
        }
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };
    if (!isPictureChanged){

      getUserInfo();
    }
  }, [isPictureChanged]);

  const handleUserProfileUpdate = async (e) => {
    e.preventDefault();
    setUserProfileData((prevData) => ({
      ...prevData,
      picture: newProfileImg,
    }));
    try {
      const res = await updateUserProfileAPI(userProfileData);
      if (res) {
        handleClose();
      } else {
        notify("Profile information update failed!", "error", 1000);
      }
    } catch (error) {
      console.error("Error updating user profile:", error);
      notify(
        "An error occurred while updating profile information",
        "error",
        1000
      );
    }
  };
  const updateUserProfile = async (newProfileImg) => {
    // handleClose();
    try {
      const res = await updateUserProfileAPI({...userProfileData, picture: newProfileImg});
      if (res) {
      } else {
        notify("Profile information update failed!", "error", 1000);
      }
    } catch (error) {
      console.error("Error updating user profile:", error);
      notify(
        "An error occurred while updating profile information",
        "error",
        1000
      );
    }
  };
  // useEffect(() => {
  //   const updateUserProfile = async () => {
  //     // handleClose();
  //     try {
  //       const res = await updateUserProfileAPI({...userProfileData, picture: newProfileImg});
  //       if (res) {
  //       } else {
  //         notify("Profile information update failed!", "error", 1000);
  //       }
  //     } catch (error) {
  //       console.error("Error updating user profile:", error);
  //       notify(
  //         "An error occurred while updating profile information",
  //         "error",
  //         1000
  //       );
  //     }
  //   };
  //   if (isPictureChanged){

  //     updateUserProfile();
  //     // setIsPictureChanged(false)
  //   }
  // }, [isPictureChanged]);

  const handleClose = () => {
    // setNewProfileImg(userProfileData.picture);
    setShow(false);
  };

  const handleImageChange = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    setNewProfileImg(base64);
    setIsPictureChanged(true);
    updateUserProfile(base64);

  };

  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const navigate = useNavigate();
  const handleDeleteUserAccount = () => {
    deleteUserAccountAPI()
      .then((res) => {
        //console.log(res);
        notify("Account deleted successfully!", "success", 1000);
        secureLocalStorage.removeItem("loginData");
        secureLocalStorage.removeItem("isLogin");
        navigate("/signup");
      })
      .catch((err) => {
        //console.log(err);
        notify("Failed to delete user account!", "error", 1000);
      });
  };
  useEffect(() => {
    if (location?.state?.forPlan){
      handleTabChange("tab2");
    }
    allChatsApi()
      .then((response) => {
        setIsSocketOn(true);
        // console.log("response in all chats:", response);
        setChatList(
          response.data.allChats != []
            ? response.data.allChats
            : {
                chatID: uniqueId,
                chatCreationTime: new Date().getTime(),
                chatData: [],
              }
        );
      })
      .catch((err) => {
        console.log("err", err);
      });
    if (checkLoginExpiration()) {
      navigate("/signin");
    }
    handleResize(); // Set initial mobileWidth value
    window.addEventListener("resize", handleResize);
    document.addEventListener("mousedown", handleClickOutside);
    socketInstance.on("message_started", (start) => {
      setIsSocketOn(false);
      console.log(start);
    });

    socketInstance.on("message_finished", (finished) => {
      setIsSocketOn(true);
      console.log(finished);
    });
    // console.log("socket", isSocketOn);
    return () => {
      socketInstance.off("message_started");
      socketInstance.off("message_finished");
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (data >= 2) {
      allChatsApi()
        .then((response) => {
          setChatList(response.data.allChats);
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, [data]);

  useEffect(() => {
    setIsVisible(mobileWidth && mobileWidth >= 800);
  }, [mobileWidth]);

  const handleResize = () => {
    setMobileWidth(window.innerWidth);
  };
  const handleTabChange = (tab) => {
    setActiveTab(tab);

  };
  const handleDrawer = () => {
    setIsVisible(!isVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setShowPersonalInfo(false);
  };
  const handlechilddropdownClick = (selectedOption) => {
    setIsOpen(false);
  };

  const createNewChat = () => {
    const chatArray = {
      chatID: uniqueId,
      chatCreationTime: new Date().getTime(),
      chatData: [],
    };
    if (data >= 2) {
      // setChatList((prevChatArray) => [chatArray, ...prevChatArray]);
      setSingleChatData(chatArray);
    }
  };

  const deleteChat = (chatid, e) => {
    e.preventDefault();
    e.stopPropagation();

    deleteChatApi(chatid)
      .then((response) => {
        // console.log("response", response);

        allChatsApi()
          .then((response) => {
            setSingleChatData({
              chatID: uniqueId,
              chatCreationTime: new Date().getTime(),
              chatData: [],
            });
            setChatList(response.data.allChats);
          })
          .catch((err) => {
            console.log("err", err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleOnIdle = () => {
    //  alert("hello I am idle")
    console.log("User is idle");
    secureLocalStorage.removeItem("loginData");
    secureLocalStorage.removeItem("isLogin");
    navigate("/signin");
    toast.info("you have been idle for so long");
  };
  useIdleTimer({
    timeout: 1000 * 60 * 60, // 1 hour
    onIdle: handleOnIdle,
  });

  return (
    <>
      {" "}
      <section
        className={`dashboard container-fluid container-space ${
          isDark ? "dark" : ""
        }`}
        data-theme={isDark ? "dark" : ""}
      >
        <div className="menuDiv"></div>

        <div className={isVisible ? "left trans-0" : "left trans-100"}>
          <div className="headingBack" data-theme={isDark ? "dark" : ""}>
            {isDark ? (
              <p className="heading">
                <img src={Logo2} alt="" />
              </p>
            ) : (
              <p className="heading">
                <img src={Logo} alt="" />
              </p>
            )}
            {/* <p className="heading">
              <img src={Logo} alt="" />
            </p> */}
            <div
              className="backBtn-div"
              onClick={handleDrawer}
              data-theme={isDark ? "dark" : ""}
            >
              {isDark ? (
                <Icon
                  icon="lets-icons:back"
                  color="white"
                  width="10"
                  height="10"
                  className="backicon"
                />
              ) : (
                <Icon
                  icon="lets-icons:back"
                  color=" #33539e"
                  width="10"
                  height="10"
                  className="backicon"
                />
              )}
            </div>
          </div>
          <div className="leftTopbar">
            <div className="topbarTitle">
              <button
                className={`${
                  activeTab === "tab1" ? "active-tab" : ""
                } new-chat`}
                onClick={() => {
                  if (isSocketOn) {
                    handleTabChange("tab1");
                    createNewChat();
                  }
                }}
              >
                {t("New_chats")}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="17"
                  viewBox="0 0 18 17"
                  fill="none"
                  className="chatsvg"
                >
                  <path
                    d="M7.83325 9.68964L0.833252 9.68964L0.833252 7.35631L7.83325 7.35631L7.83325 0.356308L10.1666 0.356308L10.1666 7.35631L17.1666 7.35631V9.68964H10.1666V16.6896H7.83325L7.83325 9.68964Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
            <div className="topbarTitle">
              {/* <div className="titleContent">
                <span className="Pinned">{t("Pinned_Chats")} </span>
              </div> */}
              {/* <div className="chatButton" data-theme={isDark ? "dark" : ""}> */}
              {/* <div className="leftdiv" data-theme={isDark ? "dark" : ""}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M5.25678 11.5793H11.753V9.95526H5.25678V11.5793ZM5.25678 9.14323H15.0011V7.51918H5.25678V9.14323ZM5.25678 6.70715H15.0011V5.0831H5.25678V6.70715ZM2.00867 18.0755V3.45904C2.00867 3.01243 2.16769 2.6301 2.48573 2.31205C2.80378 1.99401 3.18611 1.83499 3.63272 1.83499H16.6252C17.0718 1.83499 17.4541 1.99401 17.7721 2.31205C18.0902 2.6301 18.2492 3.01243 18.2492 3.45904V13.2034C18.2492 13.65 18.0902 14.0323 17.7721 14.3504C17.4541 14.6684 17.0718 14.8274 16.6252 14.8274H5.25678L2.00867 18.0755ZM4.56655 13.2034H16.6252V3.45904H3.63272V14.1169L4.56655 13.2034Z"
                      fill="black"
                      data-theme={isDark ? "dark" : ""}
                      style={{ fill: isDark ? "white" : "black" }}
                    />
                  </svg>
                  <span className="giveme" data-theme={isDark ? "dark" : ""}>
                    {t("Give_me_so")}
                  </span>
                </div> */}

              {/* <div className="leftdiv" data-theme={isDark ? "dark" : ""}>
                  <div className="withouttime">
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M5.25678 11.5793H11.753V9.95526H5.25678V11.5793ZM5.25678 9.14323H15.0011V7.51918H5.25678V9.14323ZM5.25678 6.70715H15.0011V5.0831H5.25678V6.70715ZM2.00867 18.0755V3.45904C2.00867 3.01243 2.16769 2.6301 2.48573 2.31205C2.80378 1.99401 3.18611 1.83499 3.63272 1.83499H16.6252C17.0718 1.83499 17.4541 1.99401 17.7721 2.31205C18.0902 2.6301 18.2492 3.01243 18.2492 3.45904V13.2034C18.2492 13.65 18.0902 14.0323 17.7721 14.3504C17.4541 14.6684 17.0718 14.8274 16.6252 14.8274H5.25678L2.00867 18.0755ZM4.56655 13.2034H16.6252V3.45904H3.63272V14.1169L4.56655 13.2034Z"
                        fill="black"
                        data-theme={isDark ? "dark" : ""}
                        style={{ fill: isDark ? "white" : "black" }}
                      />
                    </svg>
                    <span className="giveme" data-theme={isDark ? "dark" : ""}>
                      {t("Give_me_so")}
                    </span>
                  </div>
                  <div className="withtime">
                    {" "}
                    <span className="givemes" data-theme={isDark ? "dark" : ""}>
                      time
                    </span>
                  </div>
                </div>
                <div className="rightdiv" data-theme={isDark ? "dark" : ""}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 28 28"
                    fill="none"
                    className="delete"
                  >
                    <path
                      d="M8.16675 24.5C7.52508 24.5 6.97578 24.2715 6.51883 23.8146C6.06189 23.3576 5.83341 22.8083 5.83341 22.1667V7H4.66675V4.66667H10.5001V3.5H17.5001V4.66667H23.3334V7H22.1667V22.1667C22.1667 22.8083 21.9383 23.3576 21.4813 23.8146C21.0244 24.2715 20.4751 24.5 19.8334 24.5H8.16675ZM19.8334 7H8.16675V22.1667H19.8334V7ZM10.5001 19.8333H12.8334V9.33333H10.5001V19.8333ZM15.1667 19.8333H17.5001V9.33333H15.1667V19.8333Z"
                      fill="black"
                      data-theme={isDark ? "dark" : ""}
                    />
                  </svg>
                </div>
              </div> */}
              {/* <hr className="line2" /> */}
              <div className="titleContent">
                <span className="Pinned">{t("History")}</span>
              </div>
              <div className="bottomscrolbar">
                {/* <div className="today">
                  <span className="today">{t("today")}</span>
                </div> */}

                {chatList.length > 0
                  ? chatList
                      .slice()
                      .reverse()
                      .map((data, index) => (
                        <>
                          <div
                            onClick={() => {
                              isSocketOn &&
                                setSingleChatData({
                                  chatID: data.chatID,
                                  chatCreationTime: data.chatCreationTime,
                                  chatData: data.messages,
                                });
                            }}
                            key={data.chatID}
                            className="chatButton"
                            data-theme={isDark ? "dark" : ""}
                          >
                            <div
                              className="leftdiv"
                              data-theme={isDark ? "dark" : ""}
                            >
                              <div
                                className="withouttime"
                                // style={{ border: "1px solid red" }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <path
                                    d="M5.25678 11.5793H11.753V9.95526H5.25678V11.5793ZM5.25678 9.14323H15.0011V7.51918H5.25678V9.14323ZM5.25678 6.70715H15.0011V5.0831H5.25678V6.70715ZM2.00867 18.0755V3.45904C2.00867 3.01243 2.16769 2.6301 2.48573 2.31205C2.80378 1.99401 3.18611 1.83499 3.63272 1.83499H16.6252C17.0718 1.83499 17.4541 1.99401 17.7721 2.31205C18.0902 2.6301 18.2492 3.01243 18.2492 3.45904V13.2034C18.2492 13.65 18.0902 14.0323 17.7721 14.3504C17.4541 14.6684 17.0718 14.8274 16.6252 14.8274H5.25678L2.00867 18.0755ZM4.56655 13.2034H16.6252V3.45904H3.63272V14.1169L4.56655 13.2034Z"
                                    // fill="black"
                                    data-theme={isDark ? "dark" : ""}
                                    style={{ fill: isDark ? "white" : "black" }}
                                  />
                                </svg>
                                <span
                                  className="giveme"
                                  data-theme={isDark ? "dark" : ""}
                                >
                                  {`
                               ${data.messages && data.messages[0].content}
                               
                               ...  `}
                                </span>
                              </div>
                              <div className="withtime">
                                <span
                                  className="givemes"
                                  data-theme={isDark ? "dark" : ""}
                                >
                                  {new Date(
                                    parseInt(data.chatCreationTime)
                                  ).toLocaleTimeString(undefined, {
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true, // Use 24-hour format
                                  })}
                                </span>
                              </div>
                            </div>
                            <div
                              className="rightdiv"
                              data-theme={isDark ? "dark" : ""}
                              onClick={(e) => {
                                deleteChat(data.chatID, e);
                                notify("Chat Deleted", "success", 1000);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 28 28"
                                fill="none"
                                className="delete"
                              >
                                <path
                                  d="M8.16675 24.5C7.52508 24.5 6.97578 24.2715 6.51883 23.8146C6.06189 23.3576 5.83341 22.8083 5.83341 22.1667V7H4.66675V4.66667H10.5001V3.5H17.5001V4.66667H23.3334V7H22.1667V22.1667C22.1667 22.8083 21.9383 23.3576 21.4813 23.8146C21.0244 24.2715 20.4751 24.5 19.8334 24.5H8.16675ZM19.8334 7H8.16675V22.1667H19.8334V7ZM10.5001 19.8333H12.8334V9.33333H10.5001V19.8333ZM15.1667 19.8333H17.5001V9.33333H15.1667V19.8333Z"
                                  fill="black"
                                  data-theme={isDark ? "dark" : ""}
                                />
                              </svg>
                            </div>
                          </div>
                        </>
                      ))
                  : // <h5 style={{ color: "white" }}>No Chats Found</h5>
                    ""}

                {/* <div className="titleContent">
                  <span className="today">Last 7 Days</span>
                </div>

                <div className="lastchatbtn">
                  <div className="chatButton" data-theme={isDark ? "dark" : ""}>
                    <div className="leftdiv" data-theme={isDark ? "dark" : ""}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M5.25678 11.5793H11.753V9.95526H5.25678V11.5793ZM5.25678 9.14323H15.0011V7.51918H5.25678V9.14323ZM5.25678 6.70715H15.0011V5.0831H5.25678V6.70715ZM2.00867 18.0755V3.45904C2.00867 3.01243 2.16769 2.6301 2.48573 2.31205C2.80378 1.99401 3.18611 1.83499 3.63272 1.83499H16.6252C17.0718 1.83499 17.4541 1.99401 17.7721 2.31205C18.0902 2.6301 18.2492 3.01243 18.2492 3.45904V13.2034C18.2492 13.65 18.0902 14.0323 17.7721 14.3504C17.4541 14.6684 17.0718 14.8274 16.6252 14.8274H5.25678L2.00867 18.0755ZM4.56655 13.2034H16.6252V3.45904H3.63272V14.1169L4.56655 13.2034Z"
                          // fill="black"
                          data-theme={isDark ? "dark" : ""}
                          style={{ fill: isDark ? "white" : "black" }}
                        />
                      </svg>
                      <span
                        className="giveme"
                        data-theme={isDark ? "dark" : ""}
                      >
                        Give me so.... 10:30 PM
                      </span>
                    </div>
                    <div className="rightdiv" data-theme={isDark ? "dark" : ""}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 28 28"
                        fill="none"
                        className="delete"
                      >
                        <path
                          d="M8.16675 24.5C7.52508 24.5 6.97578 24.2715 6.51883 23.8146C6.06189 23.3576 5.83341 22.8083 5.83341 22.1667V7H4.66675V4.66667H10.5001V3.5H17.5001V4.66667H23.3334V7H22.1667V22.1667C22.1667 22.8083 21.9383 23.3576 21.4813 23.8146C21.0244 24.2715 20.4751 24.5 19.8334 24.5H8.16675ZM19.8334 7H8.16675V22.1667H19.8334V7ZM10.5001 19.8333H12.8334V9.33333H10.5001V19.8333ZM15.1667 19.8333H17.5001V9.33333H15.1667V19.8333Z"
                          fill="black"
                          data-theme={isDark ? "dark" : ""}
                        />
                      </svg>
                    </div>
                  </div>
                </div> */}
              </div>
              <div
                className="bottomSetting-section"
                data-theme={isDark ? "dark" : ""}
              >
                <div className="bottombarTitle">
                  <div className="themechangeP">
                    <div className="themechange">
                      <span
                        className="light-theme"
                        data-theme={isDark ? "dark" : ""}
                      >
                        {isDark ? "Dark Theme" : "Light Theme"}
                      </span>
                    </div>
                    <div className="togglebtn">
                      <div className="form-check form-switch togles">
                        <input
                          className="form-check-input togle"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={isDark}
                          onChange={() => setIsDark(!isDark)}
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    className={`${
                      activeTab === "tab2" ? "active-tab" : ""
                    } new-chat`}
                    onClick={() => handleTabChange("tab2")}
                    style={{
                      background:
                        activeTab === "tab2"
                          ? "var(--bg, linear-gradient(92deg, #4276D4 -15.2%, #2F4D8A 102.24%))"
                          : "white",
                      color: activeTab === "tab2" ? "white" : "black",
                    }}
                    data-theme={isDark ? "dark" : ""}
                  >
                    {" "}
                    {t("Settings")}
                    {isDark ? (
                      <Icon
                        icon="mdi:gear-outline"
                        color={activeTab === "tab2" ? "#fff" : "#fff"}
                        width="24"
                        height="24"
                      />
                    ) : (
                      <>
                        <Icon
                          icon="mdi:gear-outline"
                          color={activeTab === "tab2" ? "#fff" : "#000"}
                          width="24"
                          height="24"
                        />
                      </>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right" data-theme={isDark ? "dark" : ""}>
          <div className="rightHeader" data-theme={isDark ? "dark" : ""}>
            <div
              className="profileSection"
              ref={dropdownRef}
              data-theme={isDark ? "dark" : ""}
            >
              {/* <div className="divimg" data-theme={isDark ? "dark" : ""}>
                <img
                  src={Profile}
                  alt="profile"
                  onClick={toggleDropdown}
                  className="profileimg"
                />
              </div> */}
              <div className="divimg" data-theme={isDark ? "dark" : ""}>
                <img
                  src={!newProfileImg ? Profile : newProfileImg}
                  alt="profile"
                  onClick={toggleDropdown}
                  className="profileimg"
                />
              </div>

              {isOpen && (
                <div className="dropdownContent">
                  <div
                    className="topSection"
                    onClick={() => {
                      handleShow();
                      handlechilddropdownClick();
                    }}
                    data-theme={isDark ? "dark" : ""}
                  >
                    <div className="topLeft" data-theme={isDark ? "dark" : ""}>
                      <span
                        className="Personal-info"
                        data-theme={isDark ? "dark" : ""}
                      >
                        {t("Personal_info")}
                      </span>
                    </div>
                    <div className="topLeft" data-theme={isDark ? "dark" : ""}>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          data-theme={isDark ? "dark" : ""}
                        >
                          <path
                            d="M3.5 1.16669H17.983L24.5 7.68369V26.8334H3.5V1.16669ZM5.83333 3.50002V24.5H22.1667V8.64969L17.017 3.50002H5.83333ZM14 10.5C13.5359 10.5 13.0908 10.6844 12.7626 11.0126C12.4344 11.3408 12.25 11.7859 12.25 12.25C12.25 12.7142 12.4344 13.1593 12.7626 13.4875C13.0908 13.8156 13.5359 14 14 14C14.4641 14 14.9092 13.8156 15.2374 13.4875C15.5656 13.1593 15.75 12.7142 15.75 12.25C15.75 11.7859 15.5656 11.3408 15.2374 11.0126C14.9092 10.6844 14.4641 10.5 14 10.5ZM9.91667 12.25C9.91667 11.7138 10.0223 11.1828 10.2275 10.6874C10.4327 10.192 10.7335 9.74184 11.1126 9.36267C11.4918 8.9835 11.942 8.68272 12.4374 8.47751C12.9328 8.27231 13.4638 8.16669 14 8.16669C14.5362 8.16669 15.0672 8.27231 15.5626 8.47751C16.058 8.68272 16.5082 8.9835 16.8874 9.36267C17.2665 9.74184 17.5673 10.192 17.7725 10.6874C17.9777 11.1828 18.0833 11.7138 18.0833 12.25C18.0833 13.333 17.6531 14.3716 16.8874 15.1374C16.1216 15.9031 15.083 16.3334 14 16.3334C12.917 16.3334 11.8784 15.9031 11.1126 15.1374C10.3469 14.3716 9.91667 13.333 9.91667 12.25ZM7 22.1667C7 20.929 7.49166 19.742 8.36683 18.8669C9.242 17.9917 10.429 17.5 11.6667 17.5H16.3333C17.571 17.5 18.758 17.9917 19.6332 18.8669C20.5083 19.742 21 20.929 21 22.1667V23.3334H18.6667V22.1667C18.6667 21.5478 18.4208 20.9544 17.9832 20.5168C17.5457 20.0792 16.9522 19.8334 16.3333 19.8334H11.6667C11.0478 19.8334 10.4543 20.0792 10.0168 20.5168C9.57917 20.9544 9.33333 21.5478 9.33333 22.1667V23.3334H7V22.1667Z"
                            fill="black"
                            style={{ fill: isDark ? "white" : "black" }}
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div
                    className="topSection"
                    onClick={() => {
                      handleShow1();
                      handlechilddropdownClick();
                    }}
                    data-theme={isDark ? "dark" : ""}
                  >
                    <div className="topLeft" data-theme={isDark ? "dark" : ""}>
                      <span
                        className="Personal-info"
                        data-theme={isDark ? "dark" : ""}
                      >
                        {t("Delete_account")}
                      </span>
                    </div>
                    <div className="topLeft" data-theme={isDark ? "dark" : ""}>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="22"
                          viewBox="0 0 20 22"
                          fill="none"
                          data-theme={isDark ? "dark" : ""}
                        >
                          <path
                            d="M4.16675 21.5C3.52508 21.5 2.97578 21.2715 2.51883 20.8146C2.06189 20.3576 1.83341 19.8083 1.83341 19.1667V4H0.666748V1.66667H6.50008V0.5H13.5001V1.66667H19.3334V4H18.1667V19.1667C18.1667 19.8083 17.9383 20.3576 17.4813 20.8146C17.0244 21.2715 16.4751 21.5 15.8334 21.5H4.16675ZM15.8334 4H4.16675V19.1667H15.8334V4ZM6.50008 16.8333H8.83341V6.33333H6.50008V16.8333ZM11.1667 16.8333H13.5001V6.33333H11.1667V16.8333Z"
                            fill="black"
                            style={{ fill: isDark ? "white" : "black" }}
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div
                    className="topSection"
                    onClick={() => {
                      secureLocalStorage.removeItem("loginData");
                      secureLocalStorage.removeItem("isLogin");
                      navigate("/signin");
                      handlechilddropdownClick();
                    }}
                    data-theme={isDark ? "dark" : ""}
                  >
                    <div className="topLeft" data-theme={isDark ? "dark" : ""}>
                      <span
                        className="Personal-info"
                        data-theme={isDark ? "dark" : ""}
                      >
                        {t("Log_out")}
                      </span>
                    </div>
                    <div className="topLeft" data-theme={isDark ? "dark" : ""}>
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="29"
                          height="28"
                          viewBox="0 0 29 28"
                          fill="none"
                          data-theme={isDark ? "dark" : ""}
                        >
                          <path
                            d="M14.1126 3.79169C14.3447 3.79169 14.5673 3.88387 14.7313 4.04797C14.8954 4.21206 14.9876 4.43462 14.9876 4.66669C14.9876 4.89875 14.8954 5.12131 14.7313 5.28541C14.5673 5.4495 14.3447 5.54169 14.1126 5.54169C11.8693 5.54169 9.71793 6.43283 8.13169 8.01908C6.54544 9.60532 5.6543 11.7567 5.6543 14C5.6543 16.2433 6.54544 18.3947 8.13169 19.981C9.71793 21.5672 11.8693 22.4584 14.1126 22.4584C14.3447 22.4584 14.5673 22.5505 14.7313 22.7146C14.8954 22.8787 14.9876 23.1013 14.9876 23.3334C14.9876 23.5654 14.8954 23.788 14.7313 23.9521C14.5673 24.1162 14.3447 24.2084 14.1126 24.2084C11.4052 24.2084 8.80868 23.1328 6.89425 21.2184C4.97981 19.304 3.9043 16.7074 3.9043 14C3.9043 11.2926 4.97981 8.69607 6.89425 6.78164C8.80868 4.8672 11.4052 3.79169 14.1126 3.79169Z"
                            fill="black"
                            style={{ fill: isDark ? "white" : "black" }}
                          />
                          <path
                            d="M19.3276 11.1184C19.1731 10.9525 19.0889 10.7331 19.0929 10.5064C19.0969 10.2797 19.1888 10.0634 19.3491 9.90313C19.5094 9.74282 19.7257 9.65099 19.9524 9.64699C20.179 9.64299 20.3984 9.72713 20.5643 9.88169L24.0643 13.3817C24.2282 13.5458 24.3202 13.7681 24.3202 14C24.3202 14.2319 24.2282 14.4543 24.0643 14.6184L20.5643 18.1184C20.4842 18.2043 20.3876 18.2733 20.2803 18.3211C20.1729 18.3689 20.0571 18.3946 19.9396 18.3967C19.8221 18.3988 19.7054 18.3772 19.5964 18.3332C19.4875 18.2892 19.3885 18.2237 19.3054 18.1406C19.2223 18.0575 19.1568 17.9585 19.1128 17.8496C19.0688 17.7406 19.0472 17.6239 19.0493 17.5064C19.0513 17.3889 19.0771 17.2731 19.1249 17.1657C19.1727 17.0584 19.2417 16.9618 19.3276 16.8817L21.3343 14.875H11.7793C11.5472 14.875 11.3247 14.7828 11.1606 14.6187C10.9965 14.4546 10.9043 14.2321 10.9043 14C10.9043 13.768 10.9965 13.5454 11.1606 13.3813C11.3247 13.2172 11.5472 13.125 11.7793 13.125H21.3343L19.3276 11.1184Z"
                            fill="black"
                            style={{ fill: isDark ? "white" : "black" }}
                          />
                        </svg>
                      </span>
                    </div>
                  </div>

                  {/* <label>
                    <div
                      className="topSection"
                      data-theme={isDark ? "dark" : ""}
                      onClick={openFileSelector}
                    >
                      <div
                        className="topLeft"
                        data-theme={isDark ? "dark" : ""}
                      >
                        <span
                          className="Personal-info"
                          data-theme={isDark ? "dark" : ""}
                        >
                          Change Image
                        </span>
                      </div>
                      <div
                        className="topLeft"
                        data-theme={isDark ? "dark" : ""}
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="29"
                            height="28"
                            viewBox="0 0 29 28"
                            fill="none"
                            data-theme={isDark ? "dark" : ""}
                          >
                          </svg>
                        </span>
                      </div>
                      <input
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={handleImageChange}
                        ref={fileInputRef}
                      />
                    </div>
                  </label> */}
                </div>
              )}
            </div>
            <div className="hamburger" onClick={handleDrawer}>
              <Icon
                icon="ci:hamburger-md"
                color="#33539e"
                width="54"
                height="54"
              />
            </div>
          </div>
          {activeTab === "tab1" && (
            <Newchat chatmessage={singleChatData} isSocketOn={isSocketOn} />
          )}
          {activeTab === "tab2" && <Settings />} {/* orders */}
          {activeTab === "tab3" && <span>test3</span>} {/* My wallet */}
          {activeTab === "tab4" && <span>test4</span>} {/* wishlist */}
          {activeTab === "tab5" && <span>test5</span>} {/* referrals */}
        </div>
      </section>
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Body className="modalMainbody" data-theme={isDark ? "dark" : ""}>
          <div className="modalBody" data-theme={isDark ? "dark" : ""}>
            <form
              onSubmit={handleUserProfileUpdate}
              className="modalContent"
              data-theme={isDark ? "dark" : ""}
            >
              <div className="modalTitle " data-theme={isDark ? "dark" : ""}>
                <span
                  className="profileCustomization"
                  data-theme={isDark ? "dark" : ""}
                >
                  {t("userProfileCustomization")}
                </span>

                <label className="d-flex flex-column justify-content-center align-items-center gap-1">
                  <div
                    className="divimg divimg1"
                    data-theme={isDark ? "dark" : ""}
                  >
                    <img
                      src={!newProfileImg ? Profile : newProfileImg}
                      alt="profile"
                      // onClick={toggleDropdown}
                      className="profileimg"
                    />
                  </div>
                  <div
                    className=""
                    style={{
                      fontSize: "14px",
                      textAlign: "center",
                      fontFamily: "Inter",
                    }}
                  >
                    {t("changeImage")}
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none", cursor: "pointer" }}
                    onChange={handleImageChange}
                  />
                </label>
              </div>
              <div
                className="modalSubheading"
                data-theme={isDark ? "dark" : ""}
              >
                <span className="belowFields" data-theme={isDark ? "dark" : ""}>
                  {t("fillFieldsBelow")}
                </span>
              </div>

              <div className="modalFields" data-theme={isDark ? "dark" : ""}>
                <div className="leftside" data-theme={isDark ? "dark" : ""}>
                  <input
                    type="text"
                    required
                    placeholder={t("Name")}
                    onChange={(e) => {
                      setUserProfileData({
                        ...userProfileData,
                        full_name: e.target.value,
                      });
                    }}
                    value={userProfileData.full_name}
                    className="nameInput"
                    data-theme={isDark ? "dark" : ""}
                  />
                </div>
              </div>
              <div className="modalFields" data-theme={isDark ? "dark" : ""}>
                <div className="leftside" data-theme={isDark ? "dark" : ""}>
                  <input
                    required
                    readOnly
                    value={userProfileData.email}
                    onChange={(e) => {
                      setUserProfileData({
                        ...userProfileData,
                        email: e.target.value,
                      });
                    }}
                    type="email"
                    placeholder={t("Email")}
                    className="nameInput"
                    data-theme={isDark ? "dark" : ""}
                  />
                </div>
              </div>
              <div className="modalFields" data-theme={isDark ? "dark" : ""}>
                <div className="leftside" data-theme={isDark ? "dark" : ""}>
                  <input
                    required
                    value={userProfileData.phone_number}
                    onChange={(e) => {
                      setUserProfileData({
                        ...userProfileData,
                        phone_number: e.target.value,
                      });
                    }}
                    type="tel"
                    placeholder={t("phoneNumber")}
                    className="nameInput"
                    data-theme={isDark ? "dark" : ""}
                  />
                </div>
              </div>
              <div className="modalline" data-theme={isDark ? "dark" : ""}>
                <div
                  className="leftsideline"
                  data-theme={isDark ? "dark" : ""}
                ></div>
                <div
                  className="middlesidetext"
                  data-theme={isDark ? "dark" : ""}
                >
                  <span className="And" data-theme={isDark ? "dark" : ""}>
                    {t("And")}
                  </span>
                </div>
                <div className="leftsideline" data-theme={isDark ? "dark" : ""}>
                  <hr className="linehr" data-theme={isDark ? "dark" : ""} />
                </div>
              </div>
              <div className="modalBottom" data-theme={isDark ? "dark" : ""}>
                <div className="bottomTitle" data-theme={isDark ? "dark" : ""}>
                  <span
                    className="bottommQuestion"
                    data-theme={isDark ? "dark" : ""}
                  >
                    {t("whatWouldYouLikeToKnow")}
                  </span>
                </div>
                <div className="modalFieldss" data-theme={isDark ? "dark" : ""}>
                  <div className="leftside" data-theme={isDark ? "dark" : ""}>
                    <input
                      required
                      value={userProfileData.description}
                      onChange={(e) => {
                        setUserProfileData({
                          ...userProfileData,
                          description: e.target.value,
                        });
                      }}
                      type="text"
                      placeholder={t("typeHere")}
                      className="nameInput"
                      data-theme={isDark ? "dark" : ""}
                    />
                  </div>
                </div>
                <div className="profilemodal-btn">
                  <button className="saveButton" type="submit">
                    {t("Save")}
                  </button>
                  <button
                    onClick={handleClose}
                    type="button"
                    className="cancelButton"
                  >
                    {t("Cancel")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={show1} onHide={handleClose1} centered>
        <Modal.Body
          className="secondmaodalBody"
          data-theme={isDark ? "dark" : ""}
        >
          <div className="secondmodal" data-theme={isDark ? "dark" : ""}>
            <div
              className="secondmodalContent"
              data-theme={isDark ? "dark" : ""}
            >
              <h6 className="deleteAccount" data-theme={isDark ? "dark" : ""}>
                {" "}
                Delete Account
              </h6>
              <span className="confirmdelete" data-theme={isDark ? "dark" : ""}>
                Are you sure you want to delete your account?
              </span>
            </div>
            <div
              className="secondmodalFotter"
              data-theme={isDark ? "dark" : ""}
            >
              <div
                className="footerLeftside"
                onClick={handleClose1}
                data-theme={isDark ? "dark" : ""}
              >
                <span className="cancel" data-theme={isDark ? "dark" : ""}>
                  {t("Cancel")}
                </span>
              </div>
              <div
                className="footerLeftside"
                onClick={handleDeleteUserAccount}
                data-theme={isDark ? "dark" : ""}
              >
                <span className="delete" data-theme={isDark ? "dark" : ""}>
                  {t("Delete")}
                </span>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Dashboard;
