import React from "react";
import { useNavigate } from "react-router-dom";

const Cancel = () => {
    const navigate = useNavigate()
    const handleNavigate = () => {
        navigate('/')
    }
    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <h1 className="text-3xl font-bold mb-4">Payment Canceled</h1>
            <p className="text-lg">Your payment was canceled.</p>
            <button onClick={handleNavigate}>Back</button>
        </div>
    )
};

export default Cancel;
