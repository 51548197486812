import { useState } from "react";
import "./footer.scss";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import axios from "axios";

function Footer() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const pathsToHide = ["/b2c"];
  const shouldHideComponent = pathsToHide.includes(location.pathname);

  const currentYear = new Date().getFullYear();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(
        "https://api.hsforms.com/submissions/v3/integration/submit/45212400/7a6f6641-2b86-4476-a809-f0876bccc692",
        {
          fields: [
            {
              name: "email",
              value: email,
            },
          ],
          context: {
            pageUri: window.location.href,
            pageName: document.title,
          },
        }
      );
      console.log(res);
      setEmail("");
      setMessage("Thank you for subscribing!");
    } catch (err) {
      setMessage("There was an error. Please try again.");
    }
  };

  return (
    <>
      <div className="footer-section">
        <div className="footer-content">
          <div className="footer">
            <div className="first">
              <div className="first-content">
                <img
                  src="/assets/images/logo.svg"
                  alt="..."
                  className="logo"
                  style={{ cursor: "pointer" }}
                />
                <p className="first-para">
                  {t("streamliningCustomerInteractions")}
                </p>
              </div>
            </div>
            <div className="first">
              <div className="heading">
                <h5>{t("pages")}</h5>
              </div>
              <ul>
                <li>
                  <NavLink
                    to={shouldHideComponent ? "/aboutus-b2c" : "/aboutus"}
                  >
                    {t("about")}
                  </NavLink>
                </li>

                <li>
                  <NavLink to={"/contact"}>{t("contact")}</NavLink>
                </li>
                <li>
                  <NavLink
                    href="#Frequently"
                    to={shouldHideComponent ? "/services-b2c" : "/services"}
                  >
                    Services
                  </NavLink>
                </li>
              </ul>
            </div>

            <div className="first">
              <div className="heading">
                <h5>{t("Subscribe")}</h5>
              </div>
              <p className="newsletter-para">{t("newsletter")}</p>
              <form className="inputfield" onSubmit={handleSubmit}>
                <input
                  type="email"
                  required
                  value={email}
                  inputMode="email"
                  placeholder={t("enterAddress")}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button className="btn-sign" type="submit">
                  {t("signUp")}
                </button>
              </form>
              <div className="agree-content">
                <span>{t("privacyPolicy")}</span>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="left-side">
              <p className="copy-right">
                @{currentYear} lawvocate.ai All Rights Reserved
              </p>
              <span
                className="privacy pointer"
                onClick={() => {
                  navigate("/privacy-policy");
                  scrollToTop();
                }}
              >
                {t("PrivacyPolicy")}
              </span>
              <span
                className="terms pointer"
                onClick={() => {
                  navigate("/termsofservice");
                  scrollToTop();
                }}
              >
                {t("termsOfService")}
              </span>
            </div>
            <div className="right-side">
              <Icon
                icon="mdi:linkedin"
                width="40"
                height="40"
                color="#4176D4"
              />
            </div>
          </div>

          <div className="footer-bottom  ">
            <p
              className="mt-2"
              style={{
                fontSize: "19px",
                color: "#5B5B5B",
                fontFamily: "Inter",
              }}
            >
              <span style={{ fontWeight: "500" }}>Disclaimer: </span> The
              content on this website is for informational purposes only and
              does not constitute legal advice.{" "}
              <a
                className="text-light-blue"
                onClick={() => {
                  navigate("/disclaimer");
                  scrollToTop();
                }}
              >
                Read the Full Disclaimer.
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
